//  Modules from the React eco-system
import React, { useState } from "react"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next";

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import Cookies from "universal-cookie"
import { toast } from "react-toastify"

// axios
import axios, { AxiosError } from "axios"
import { apiUrl } from "../config/config"

// redux
import { setIsAuthenticated } from "../store/userSlice"

// style, bootstrap, icons
import { Form, Button } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiEyeOff, mdiEye, mdiChevronRight } from "@mdi/js"

// images
import logo_spotmonitor from "../assets/img/logo-spotmonitor-white.png";

function Login() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    // show/hide password
    const [showPassword, setShowPassword] = useState(false)
    const [licenseError, setLicenseError] = useState('')

    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
            password: Yup.string().required("Password is a required field"),
        }),
        onSubmit: async (values) => {
            const cookies = new Cookies()
            try {
                const response = await axios.post(`${apiUrl}/auth/admin/login`, values)
                cookies.set("token", response.data.token, { path: "/" })
                cookies.set("refresh_token", response.data.refresh_token, { path: "/" })
		console.log(response);
                dispatch(setIsAuthenticated(true))
                history.push("/admin")
            } catch (error) {
		setLicenseError('A licensz lejárt kérem vegye fel velünk a kapcsolatot: bence.dragsits@spotmonitor.hu; Tel: +36205875533') 
		//console.log(error);
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to login")
                }
            }
        },
    })

    const formikForgotPassword = useFormik({
        initialValues: {
            requestEmail: "",
        },
        validationSchema: Yup.object({
            requestEmail: Yup.string().email("Invalid email address").required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await axios.post(`${apiUrl}/auth/admin/forgot-password/request`, { email: values.requestEmail })
                toast.success("Email sent")
                resetForm()
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to send email")
                }
            }
        },
    })

    return (
        <div className="login-screen">
            <div className="login-container">
                <div className="logo-container">
                    <img className="logo-picture" alt="logo-login" src={logo_spotmonitor} />
                </div>

                <div className="form-container">
                    {!showForgotPasswordForm ? (
                        <>
                            <p>{t('Enter your email and password below')}</p>
                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group className="form-group" controlId="formBasicEmail">
                                    <Form.Label>{t('Email')}</Form.Label>
                                    <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder={t('Email')} />
                                    {formik.touched.email && formik.errors.email ? <Form.Text className="input-error">{formik.errors.email}</Form.Text> : null}
                                </Form.Group>

                                <Form.Group className="form-group" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        onChange={formik.handleChange}
                                        name="password"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        type={showPassword ? "text" : "password"}
                                        placeholder={t('Password')}
                                    />
                                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                        <Icon path={showPassword ? mdiEye : mdiEyeOff} size={0.7} />
                                    </span>
                                    {formik.touched.password && formik.errors.password ? <Form.Text className="input-error">{formik.errors.password}</Form.Text> : null}
                                </Form.Group>
				<p className="input-error">{licenseError}</p>
                                <Button variant="primary" type="submit" className="login-button orange">
                                    {t('Log In')}
                                </Button>
                            </Form>
                            <div className="change-view-container">
                                <p onClick={() => setShowForgotPasswordForm(true)}>
                                    <span>{t('Forgot password')}</span>
                                    <Icon path={mdiChevronRight} size={0.7} />
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <Form onSubmit={formikForgotPassword.handleSubmit}>
                                <Form.Group className="form-group" controlId="formBasicEmail">
                                    <Form.Label>{t('Email')}</Form.Label>
                                    <Form.Control
                                        onChange={formikForgotPassword.handleChange}
                                        name="requestEmail"
                                        onBlur={formikForgotPassword.handleBlur}
                                        value={formikForgotPassword.values.requestEmail}
                                        type="email"
                                        placeholder={t('Email')}
                                    />
                                    {formikForgotPassword.touched.requestEmail && formikForgotPassword.errors.requestEmail ? (
                                        <Form.Text className="input-error">{formikForgotPassword.errors.requestEmail}</Form.Text>
                                    ) : null}
                                </Form.Group>

                                <Button variant="primary" type="submit" className="login-button">
                                    {t('Send Email')}
                                </Button>
                            </Form>
                            <div className="change-view-container">
                                <p onClick={() => setShowForgotPasswordForm(false)}>
                                    <span>{t('Login')}</span>
                                    <Icon path={mdiChevronRight} size={0.7} />
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Login
